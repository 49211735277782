.flex {
  display: flex;
  align-items: center;
  padding: 1rem 0 1rem 0;
}

.contact-icons {
  margin-right: 1rem;
}

.external-link:hover {
  color: white;
}

#contact {
  padding-top: 5rem;
  position: relative;
}
