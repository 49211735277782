@font-face {
  font-family: SFmono;
  src: url("../../assets/SFMonoRegular.otf");
  font-weight: 400;
}

.nav-link {
  font-family: SFmono, Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
  cursor: pointer;
  opacity: 0;
  transform: translateY(-60%);
  animation: fadein 0.3s ease-in forwards;
}

@keyframes fadein {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-link:nth-child(1) {
  animation-delay: 100ms;
}
.nav-link:nth-child(2) {
  animation-delay: 200ms;
}
.nav-link:nth-child(3) {
  animation-delay: 300ms;
}
.nav-link:nth-child(4) {
  animation-delay: 400ms;
}
.nav-link:nth-child(5) {
  animation-delay: 500ms;
}

.nav-container {
  color: white;
}

.nav-link:hover {
  transform: scale(1.1);
  text-shadow: 2px 2px 2px rgb(144, 144, 144);
}

.nav-container.scroll {
  background-color: #112240;
  transition: 0.3s ease-in-out;
}

@media (max-width: 600px) {
  .nav-container {
    display: none !important;
    flex-direction: column;
    background-color: #112240;
    row-gap: 0.5rem;
    padding: 0.5rem;
  }

  .show {
    display: flex !important;
  }
}
