.background {
  background-color: rgb(10, 25, 47);
  height: 100vh;
  z-index: -2;
}

.overlay {
  background-color: #f4edea;
  height: 100vh;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.hero-banner {
  position: relative;
  height: 100vh;
}

.github-icon:hover {
  transform: scale(1.1);
}

.linkedin-icon:hover {
  transform: scale(1.1);
}

.particlesCanvas {
  width: 100% !important;
  height: 100% !important;
  bottom: 0;
  right: 0;
}

.header-1 {
  transform: translateY(20%);
  opacity: 0;
  animation: fadein 0.3s 0.7s ease-in forwards;
}

.header-2 {
  transform: translateY(20%);
  opacity: 0;
  animation: fadein 0.3s 0.9s ease-in forwards;
}

.header-icon {
  transform: translateY(20%);
  opacity: 0;
  animation: fadein 0.3s 1.1s ease-in forwards;
}

@keyframes fadein {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.email {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
}
