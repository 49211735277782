.dev-icons {
  font-size: 4rem;
}

.icon-express {
  color: rgb(255, 255, 255);
}

@media (max-width: 500px) {
  .dev-icons {
    font-size: 2.5rem;
  }
}
