.chip-icons {
  font-size: 0.5rem;
}

#projects {
  padding-bottom: 20px;
  position: relative;
}

.project-icon:hover {
  color: #64ffda;
}

.project-wrapper:nth-child(odd) {
  flex-direction: row-reverse;
}

.project-title::after {
  content: "";
  display: flex;
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: #64ffda;
  margin-top: 0.2rem;
}
